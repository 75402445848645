<template>
  <a-form
    :label-col="{ span: 4 }"
    :wrapper-col="{ span: 20 }"
    :model="form"
    :rules="rules"
    ref="formRef"
  >
    <a-form-item label="名称" name="promoteName" required>
      <a-input
        v-model:value="form.promoteName"
        placeholder="请输入名称"
        style="width: 80%"
      />
    </a-form-item>
    <a-form-item label="内容" name="promoteUrlType">
      <a-select
        v-model:value="form.promoteUrlType"
        placeholder="请选择"
        style="width: 80%"
      >
        <a-select-option value="0">自定义链接</a-select-option>
        <a-select-option value="1">留资表单</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item
      label="链接"
      name="promoteUrl"
      v-if="form.promoteUrlType !== '1'"
      required
    >
      <a-input
        v-model:value="form.promoteUrl"
        placeholder="请输入链接"
        style="width: 80%"
      />
    </a-form-item>
    <a-form-item
      v-if="form.promoteUrlType === '1'"
      label="banner图"
      extra="建议上传大小不超过10M，格式为JPG、JPEG或PNG的图片"
    >
      <iss-image-upload
        v-model:value="form.banner"
        :limit-size="10"
        list-type="picture-card"
        accept=".jpe,.jpeg,.jpg,.png"
      />
    </a-form-item>

    <a-form-item label="裂变积分">
      <a-switch
        v-model:checked="form.isPoints"
        checked-children="开"
        un-checked-children="关"
      />
    </a-form-item>
    <div v-if="form.isShow">
      <a-form-item label="首次成为传播者">
        <a-input-number
          v-model:value="form.pointsConfig.FIRST_TRANSFER"
          placeholder="请输入正整数"
          style="width: 30%"
          :max="100"
          :min="0"
          @blur="getInputNumber"
        />
      </a-form-item>
      <a-form-item label="新增一个传播者">
        <a-input-number
          v-model:value="form.pointsConfig.ADD_TRANSFER"
          placeholder="请输入正整数"
          style="width: 30%"
          :max="100"
          :min="0"
          @blur="getInputNumber"
        />
      </a-form-item>
      <a-form-item label="新增一个留资">
        <a-input-number
          v-model:value="form.pointsConfig.ADD_RETENTION"
          placeholder="请输入正整数"
          style="width: 30%"
          :max="100"
          :min="0"
          @blur="getInputNumber"
        />
      </a-form-item>
      <!-- :labelCol="{ span: 3, offset: 1 }" -->
      <a-form-item label="新增一个访问人数">
        <a-input-number
          v-model:value="form.pointsConfig.ADD_VISITOR"
          placeholder="请输入正整数"
          style="width: 30%"
          :max="100"
          :min="0"
          @blur="getInputNumber"
        />
      </a-form-item>

      <a-form-item label="新增一个访问次数">
        <a-input-number
          v-model:value="form.pointsConfig.ADD_VISITE"
          placeholder="请输入正整数"
          style="width: 30%"
          :max="100"
          :min="0"
          @blur="getInputNumber"
        />
      </a-form-item>

      <a-form-item label="积分活动规则说明">
        <a-textarea
          v-model:value="form.pointsDescribe"
          show-count
          :maxlength="500"
          style="width: 80%"
          placeholder="请输入积分活动规则说明"
        />
      </a-form-item>
    </div>

    <a-row>
      <a-col :push="3">
        <a-space style="color: #9d9d9d">
          <!--              创建后，请至“推广配置”设置链接式推广-分享设置-->
        </a-space>
      </a-col>
    </a-row>
    <br />
  </a-form>
  <a-space>
    <a-button type="primary" @click="save">保存</a-button>
    <a-button @click="closeModal">取消</a-button>
  </a-space>
</template>
<script>
import { reactive, ref, watch } from 'vue';
import promoteApi from '@/api/promote';
import IssImageUpload from '@/components/imageUpload';
import {
  Space,
  Form,
  Row,
  Col,
  message,
  Select,
  InputNumber,
  Switch,
} from 'ant-design-vue';
export default {
  components: {
    ASpace: Space,
    ARow: Row,
    ACol: Col,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    IssImageUpload,
    AInputNumber: InputNumber,
    ASwitch: Switch,
  },
  setup(props, context) {
    const formRef = ref();
    const form = reactive({
      promoteName: '',
      promoteUrl: '',
      promoteType: 5,
      enabled: 1,
      promoteUrlType: null,
      banner: '',

      isShow: true,
      isPoints: true,
      // 积分字段放在 pointsConfig
      pointsConfig: {
        FIRST_TRANSFER: '20',
        ADD_TRANSFER: '10',
        ADD_RETENTION: '10',
        ADD_VISITOR: '4',
        ADD_VISITE: '1',
      },
      pointsDescribe:
        '活动期间，通过微信授权成为传播者，将物料传播给他人，从而带来的浏览量、留资数和二次传播均可使自己获得积分。',
    });
    const required = { required: true, message: '不能为空', trigger: 'blur' };
    const rules = {
      promoteName: [required, { max: 50, message: '不可超过50字' }],
      promoteUrlType: [required],
      promoteUrl: [
        required,
        { type: 'url', message: '请输入正确的URL地址，以http或者https开头' },
      ],
      // promoteUrlType: [required],
    };

    watch(
      () => form.isPoints,
      () => {
        form.isShow = form.isPoints;
      }
    );

    const save = () => {
      formRef.value.validate().then(() => {
        if (form.isPoints) {
          for (let key in form.pointsConfig) {
            let num = form.pointsConfig[key];

            if (num === '' || num === null) {
              return message.error('请配置好完整的积分规则');
            }
          }
        }

        promoteApi.promoteAdd('', form).then(() => {
          message.success('操作成功');
          context.emit('saveOnCloseModal', false);
        });
        closeModal();
      });
    };
    const closeModal = () => {
      context.emit('closeModal', false);
    };
    return {
      form,
      save,
      rules,
      formRef,
      closeModal,
      radioOptions: [
        {
          label: '推广传播',
          value: '1',
        },
        {
          label: '留资获客',
          value: '2',
        },
      ],

      getInputNumber: () => {
        for (let key in form.pointsConfig) {
          let num = form.pointsConfig[key];

          if (num % 1 !== 0) {
            form.pointsConfig[key] = '';
            return message.error('请输入整正数');
          }
        }
      },
    };
  },
};
</script>
