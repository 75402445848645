<template>
  <div id="tabTow">
    <search-form
      :items="items"
      :layout="{ sm: 24, md: 12, lg: 8 }"
      :container="getContainer"
      @fnSearch="handleFnSearch"
    />
    <grid
      style="margin-top: 20px"
      :url="url"
      ref="gridRef"
      :search="search"
      :columns="columns"
      :url-params="{ tabId }"
      :code="$route.path"
      :btn-operation="btnOperation"
      :scroll="{ x: 900, y: gridHeight }"
      :allow-selection="{ type: 'radio' }"
    >
      <!-- <template #sendChannel="{ text }">
        <span v-if="text == 'SMS'">短信</span>
        <span v-else-if="text == 'EMAIL'">邮件</span>
        <span v-else-if="text == 'WECHAT'">微信</span>
        <span v-else>无</span>
      </template> -->

      <template #tabId="{ text }">
        {{ text ? (text == '1' ? '文章' : '文件') : '-' }}
      </template>
    </grid>
    <br />
    <a-form ref="formRef" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }">
      <a-form-item
        label="banner图"
        extra="建议上传大小不超过10M，格式为JPG、JPEG或PNG的图片"
      >
        <iss-image-upload
          v-model:value="form.banner"
          :limit-size="10"
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
        />
      </a-form-item>
      <a-form-item label="说明">
        <a-textarea v-model:value="form.remark" placeholder="请输入说明" />
      </a-form-item>
      <a-form-item label="是否裂变">
        <a-switch
          v-model:checked="form.isFission"
          checked-children="开"
          un-checked-children="关"
        />
      </a-form-item>
      <a-form-item label="裂变积分">
        <a-switch
          v-model:checked="form.isPoints"
          checked-children="开"
          un-checked-children="关"
          :disabled="!form.isFission"
        />
      </a-form-item>
      <div v-if="form.isShow">
        <a-form-item label="首次成为传播者">
          <a-input-number
            v-model:value="form.pointsConfig.FIRST_TRANSFER"
            placeholder="请输入正整数"
            style="width: 30%"
            :max="100"
            :min="0"
            @blur="getInputNumber"
          />
        </a-form-item>
        <a-form-item label="新增一个传播者">
          <a-input-number
            v-model:value="form.pointsConfig.ADD_TRANSFER"
            placeholder="请输入正整数"
            style="width: 30%"
            :max="100"
            :min="0"
            @blur="getInputNumber"
          />
        </a-form-item>
        <a-form-item label="新增一个留资">
          <a-input-number
            v-model:value="form.pointsConfig.ADD_RETENTION"
            placeholder="请输入正整数"
            style="width: 30%"
            :max="100"
            :min="0"
            @blur="getInputNumber"
          />
        </a-form-item>

        <a-form-item label="新增一个访问人数">
          <a-input-number
            v-model:value="form.pointsConfig.ADD_VISITOR"
            placeholder="请输入正整数"
            style="width: 30%"
            :max="100"
            :min="0"
            @blur="getInputNumber"
          />
        </a-form-item>

        <a-form-item label="新增一个访问次数">
          <a-input-number
            v-model:value="form.pointsConfig.ADD_VISITE"
            placeholder="请输入正整数"
            style="width: 30%"
            :max="100"
            :min="0"
            @blur="getInputNumber"
          />
        </a-form-item>

        <a-form-item label="积分活动规则说明">
          <a-textarea
            v-model:value="form.pointsDescribe"
            show-count
            :maxlength="500"
            placeholder="请输入积分活动规则说明"
          />
        </a-form-item>
      </div>
    </a-form>
    <br />
    <a-space>
      <a-button type="primary" @click="save">保存</a-button>
      <a-button @click="closeModal">取消</a-button>
    </a-space>
    <a-modal
      v-model:visible="visible"
      :footer="null"
      centered
      :title="modalTitle"
    >
      <div v-html="editorData"></div>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, ref, toRaw, watch } from 'vue';
import Grid from '@/components/grid';
import promoteApi from '@/api/promote';
// import { useRoute } from 'vue-router';
import SearchForm from '@/components/searchForm';
import { Space, message, Form, Switch, InputNumber } from 'ant-design-vue';
import IssImageUpload from '@/components/imageUpload';
export default {
  components: {
    Grid,
    SearchForm,
    ASpace: Space,
    AForm: Form,
    AFormItem: Form.Item,
    IssImageUpload,
    ASwitch: Switch,
    AInputNumber: InputNumber,
  },
  props: {
    tabId: String,
  },
  setup(props, context) {
    // const formRef = ref();
    const gridRef = ref();
    const modalTabTowRef = ref();
    // const route = useRoute();
    const state = reactive({
      search: {},
      resource: [],
      visible: false,
      editorData: null,
      modalTitle: null,
      selectTabId: 1,
    });

    const form = reactive({
      banner: null,
      remark: '',
      isFission: true,
      isPoints: true,
      isShow: true,

      // 积分字段放在 pointsConfig
      pointsConfig: {
        FIRST_TRANSFER: '20',
        ADD_TRANSFER: '10',
        ADD_RETENTION: '10',
        ADD_VISITOR: '4',
        ADD_VISITE: '1',
      },
      pointsDescribe:
        '活动期间，通过微信授权成为传播者，将物料传播给他人，从而带来的浏览量、留资数和二次传播均可使自己获得积分。',
    });

    watch(
      () => form.isPoints,
      () => {
        form.isShow = form.isPoints;
      }
    );
    watch(
      () => form.isFission,
      () => {
        form.isPoints = form.isFission;
      }
    );

    const save = () => {
      const rowData = gridRef.value.selectedRows;
      if (form.isPoints) {
        for (let key in form.pointsConfig) {
          let num = form.pointsConfig[key];

          if (num === '' || num === null) {
            return message.error('请配置好完整的积分规则');
          }
        }
      }

      if (rowData.length > 0) {
        rowData.map(i => {
          let da = {
            resourceId: i.id,
            resourceType: i.fileType,
            promoteName: i.fileName,
            promoteType: 2,
            enabled: 1,
            ...toRaw(form),
          };
          promoteApi.promoteAdd('', { ...da }).then(() => {
            message.success('操作成功');
            context.emit('saveOnCloseModal', false);
          });
        });
      } else {
        message.error('请选择推广任务');
      }
    };

    const closeModal = () => {
      context.emit('closeModal', false);
    };

    const items = [
      { key: 'fileName', label: '文章名称' },
      // {
      //   key: 'tabId',
      //   label: '资料类型',
      //   type: 'select',
      //   valueKey: 'id',
      //   labelKey: 'tabName',
      //   url: promoteApi.resourceResourceTabUrl,
      //   linkedKey: 'categoryId',
      //   fnChange: ({ value }) => {
      //     items[2].urlParams = { tabId: value };
      //   },
      // },
      // {
      //   key: 'categoryId', // 字段名
      //   label: '资料类别',
      //   type: 'treeSelect',
      //   url: promoteApi.ResourceCategoryVoList,
      //   urlParams: { tabId: null }, // 接口参数
      //   options: {
      //     // 组件api
      //     replaceFields: {
      //       key: 'id',
      //       value: 'id',
      //       children: 'resourceCategoryList',
      //       title: 'categoryName',
      //     },
      //   },
      // },
    ];

    return {
      form,
      save,
      items,
      gridRef,
      modalTabTowRef,
      closeModal,
      ...toRefs(state),
      url: promoteApi.resourceFilePageUrl,
      radioOptions: [
        {
          label: '推广传播',
          value: '1',
        },
        {
          label: '留资获客',
          value: '2',
        },
      ],
      columns: [
        { dataIndex: 'fileName', title: '文章名称', width: 180 },
        {
          dataIndex: 'tabId',
          title: '类型',
          width: 110,
          slots: { customRender: 'tabId' },
        },
        {
          dataIndex: 'categoryName',
          title: '类别',
          width: 120,
          slots: { customRender: 'categoryName' },
        },
      ],
      gridHeight: document.body.clientHeight - 608,

      handleFnSearch: value => {
        state.search = value;
      },
      getContainer: () => window.document.getElementById('tabTow'),
      getInputNumber: () => {
        for (let key in form.pointsConfig) {
          let num = form.pointsConfig[key];

          if (num % 1 !== 0) {
            form.pointsConfig[key] = '';
            return message.error('请输入整正数');
          }
        }
      },
    };
  },
};
</script>

<style lang="less" scoped>
.ant-form-item-label {
  text-align: left !important;
}
</style>
