<template>
  <div id="tabTow">
    <search-form
      :items="items"
      :layout="{ sm: 24, md: 12, lg: 8 }"
      :container="getContainer"
      @fnSearch="handleFnSearch"
    />
    <grid
      style="margin-top: 20px"
      :url="url"
      ref="gridRef"
      :search="search"
      :columns="columns"
      :url-params="{ tabId }"
      :code="$route.path"
      :btn-operation="btnOperation"
      :scroll="{ x: 900, y: gridHeight }"
      :allow-selection="{ type: 'radio' }"
    >
      <!-- <template #sendChannel="{ text }">
        <span v-if="text == 'SMS'">短信</span>
        <span v-else-if="text == 'EMAIL'">邮件</span>
        <span v-else-if="text == 'WECHAT'">微信</span>
        <span v-else>无</span>
      </template> -->

      <template #tabId="{ text }">
        {{ text ? (text == '1' ? '文章' : '文件') : '-' }}
      </template>

      <template #fileUrl="{ record }">
        <EyeOutlined
          style="font-size: 22px; color: #5979f8"
          @click="handleFnUrl(record)"
        />
      </template>
    </grid>
    <br />
    <a-form
      ref="formRef"
      :model="form"
      :label-col="{ span: 2 }"
      :wrapper-col="{ span: 20 }"
    >
      <a-form-item
        label="banner图"
        extra="建议上传大小不超过10M，格式为JPG、JPEG或PNG的图片"
      >
        <iss-image-upload
          v-model:value="form.banner"
          :limit-size="10"
          list-type="picture-card"
          accept=".jpe,.jpeg,.jpg,.png"
        />
      </a-form-item>
      <a-form-item label="说明">
        <a-textarea v-model:value="form.remark" placeholder="请输入说明" />
      </a-form-item>
      <!--      <a-form-item label="是否裂变">-->
      <!--        <a-switch-->
      <!--          v-model:checked="form.isFission"-->
      <!--          checked-children="开"-->
      <!--          un-checked-children="关"-->
      <!--        />-->
      <!--      </a-form-item>-->
    </a-form>
    <br />
    <a-space>
      <a-button type="primary" @click="save">保存</a-button>
      <a-button @click="closeModal">取消</a-button>
    </a-space>
    <a-modal
      v-model:visible="visible"
      :footer="null"
      centered
      :title="modalTitle"
    >
      <div v-html="editorData"></div>
    </a-modal>
  </div>
</template>
<script>
import { reactive, toRefs, ref, toRaw } from 'vue';
import Grid from '@/components/grid';
import promoteApi from '@/api/promote';
// import { useRoute } from 'vue-router';
import IssImageUpload from '@/components/imageUpload';
import { local } from '@/utils/storage';
import SearchForm from '@/components/searchForm';
import { EyeOutlined } from '@ant-design/icons-vue';
import { Space, message, Form /*Switch*/ } from 'ant-design-vue';
export default {
  components: {
    Grid,
    EyeOutlined,
    SearchForm,
    ASpace: Space,
    IssImageUpload,
    AForm: Form,
    AFormItem: Form.Item,
    // ASwitch: Switch,
  },
  props: {
    tabId: String,
  },
  setup(props, context) {
    const formRef = ref();
    const form = reactive({
      marketingType: 2,
      promoteType: 2,
      banner: '',
      resourceType: 'file',
      remark: '',
      isFission: false,
    });
    const gridRef = ref();
    const modalTabTowRef = ref();
    // const route = useRoute();
    const state = reactive({
      search: {},
      resource: [],
      visible: false,
      editorData: null,
      modalTitle: null,
      selectTabId: 1,
    });
    const save = () => {
      formRef.value.validate().then(() => {
        const rowData = gridRef.value.selectedRows;
        if (rowData.length > 0) {
          rowData.map(i => {
            let da = {
              resourceId: i.id,
              promoteName: i.fileName,
              promoteType: 2,
              enabled: 1,
              ...toRaw(form),
            };
            promoteApi.promoteAdd('', { ...da }).then(() => {
              message.success('操作成功');
              context.emit('saveOnCloseModal', false);
            });
          });
        } else {
          message.error('请选择推广任务');
        }
      });
    };
    const closeModal = () => {
      context.emit('closeModal', false);
    };

    const items = [
      { key: 'fileName', label: '资料名称' },
      // {
      //   key: 'tabId',
      //   label: '资料类型',
      //   type: 'select',
      //   valueKey: 'id',
      //   labelKey: 'tabName',
      //   url: promoteApi.resourceResourceTabUrl,
      //   linkedKey: 'categoryId',
      //   fnChange: ({ value }) => {
      //     items[2].urlParams = { tabId: value };
      //   },
      // },
      // {
      //   key: 'categoryId', // 字段名
      //   label: '资料类别',
      //   type: 'treeSelect',
      //   url: promoteApi.ResourceCategoryVoList,
      //   urlParams: { tabId: null }, // 接口参数
      //   options: {
      //     // 组件api
      //     replaceFields: {
      //       key: 'id',
      //       value: 'id',
      //       children: 'resourceCategoryList',
      //       title: 'categoryName',
      //     },
      //   },
      // },
    ];

    return {
      save,
      items,
      formRef,
      form,
      gridRef,
      modalTabTowRef,
      closeModal,
      ...toRefs(state),
      url: promoteApi.resourceFilePageUrl,
      radioOptions: [
        {
          label: '推广传播',
          value: '1',
        },
        {
          label: '留资获客',
          value: '2',
        },
      ],
      columns: [
        { dataIndex: 'fileName', title: '资料名称', width: 180 },
        // {
        //   dataIndex: 'tabId',
        //   title: '资料类型',
        //   width: 110,
        //   slots: { customRender: 'tabId' },
        // },
        {
          dataIndex: 'categoryName',
          title: '资料类别',
          width: 120,
          slots: { customRender: 'categoryName' },
        },

        {
          dataIndex: 'fileUrl',
          title: '预览',
          width: 50,
          slots: { customRender: 'fileUrl' },
        },
      ],
      gridHeight: document.body.clientHeight - 608,
      handleFnSearch: value => {
        state.search = value;
      },
      handleFnUrl: ({ id }) => {
        promoteApi.promoteDataPreview('', id).then(res => {
          if (!res) return message.error('无资料预览');
          state.modalTitle = res.fileName;
          if (res.fileType == 'article') {
            // state.editorData = res.article;
            // state.visible = true;
            let time = new Date().getTime();
            time = time + 3600 * 1000;
            document.cookie = `resourceId={"id":"${
              res.id
            }","token":"${local.get(
              'TOKEN'
            )}"}; expires=${time};path=/; domain=.issmart.com.cn`;
            window.open(`${process.env.VUE_APP_CLIENT_URL}/#/preview`);
          } else {
            window.open(res.fileUrl, '_blank');
          }
        });
      },
      getContainer: () => window.document.getElementById('tabTow'),
    };
  },
};
</script>
