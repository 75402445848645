<template>
  <div class="iss-main-grid" ref="mainRef">
    <div class="iss-search-wrap">
      <search-form
        :items="items"
        :layout="{ sm: 24, md: 12, lg: 8 }"
        :default-value="search"
        @fnSearch="handleFnSearch"
      />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        :columns="columns"
        :code="$route.path"
        :url="url"
        :url-params="{ entry: 1 }"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1200, y: gridHeight }"
      >
        <template #promoteName="{ text }">
          <span>{{ text.promoteName }}</span>
        </template>
        <template #promoteType="{ record }">
          <span v-if="record.promoteType == 1">会议邀请</span>
          <span v-else-if="record.promoteType == 2">{{
            record.resourceType === 'file' ? '资料推广' : '图文任务'
          }}</span>
          <span v-else-if="record.promoteType == 5">裂变海报</span>
        </template>
        <template #conversionNumber="{ record }">
          <span v-if="record.resourceType === 'article'">-</span>
          <span v-else-if="record.promoteUrlType === '0'">-</span>
          <span v-else>{{ record.conversionNumber }}</span>
        </template>
        <!--      <template #enabled="{ record }">-->
        <!--        <a-switch :checked="record.enabled" @click="handleByEnabled(record)" />-->
        <!--      </template>-->
        <template #operation="{ record }">
          <operation :options="getOptions(record)" :record="record" />
        </template>
      </grid>
    </div>
  </div>
  <a-modal
    v-model:visible="visible"
    ref="modalRef"
    id="modalRef"
    :title="'新建推广内容'"
    :width="1200"
    class="modal"
    destroyOnClose
    :getContainer="mainRef"
    centered
    :footer="null"
    :mask-closable="false"
  >
    <a-row>
      <a-col :span="4">
        <a-button
          :type="activeKey === 1 && 'primary'"
          @click="activeKey = 1"
          size="large"
          >会议邀请</a-button
        ><br /><br />
        <a-button
          :type="activeKey === 2 && 'primary'"
          @click="activeKey = 2"
          size="large"
          >资料推广</a-button
        ><br /><br />
        <a-button
          :type="activeKey === 4 && 'primary'"
          @click="activeKey = 4"
          size="large"
          >图文任务</a-button
        ><br /><br />
        <a-button
          :type="activeKey === 5 && 'primary'"
          @click="activeKey = 5"
          size="large"
          >裂变海报</a-button
        >

        <!--        <a-button-->
        <!--          :type="activeKey === 3 && 'primary'"-->
        <!--          @click="activeKey = 3"-->
        <!--          size="large"-->
        <!--        >自定义</a-button-->
        <!--        >-->
      </a-col>
      <a-col v-show="activeKey === 1" :span="20">
        <MTOne
          @closeModal="closeModal"
          @saveOnCloseModal="saveOnCloseModal"
          v-if="show !== 0"
        />
        <div class="not-open" v-if="show === 0">
          <a-avatar :size="76" :style="{ 'background-color': '#F7A200' }">
            <template #icon>
              <ExclamationOutlined :style="{ color: '#fff' }" />
            </template> </a-avatar
          ><br />
          <h2><b>目前没有进行中和未开始的会议</b></h2>
          <span>请先创建会议</span><br />
        </div>
      </a-col>
      <!--   资料推广  -->
      <a-col v-show="activeKey === 2" :span="20">
        <MTTwo
          @closeModal="closeModal"
          @saveOnCloseModal="saveOnCloseModal"
          tab-id="2"
        />
      </a-col>
      <!--    图文推广  -->
      <a-col v-show="activeKey === 4" :span="20">
        <MTFour
          @closeModal="closeModal"
          @saveOnCloseModal="saveOnCloseModal"
          tab-id="1"
        />
      </a-col>
      <a-col v-show="activeKey === 5" :span="20">
        <MTFive @closeModal="closeModal" @saveOnCloseModal="saveOnCloseModal" />
      </a-col>
      <!--      <a-col v-show="activeKey === 3" :span="20">-->
      <!--        <MTThree-->
      <!--          @closeModal="closeModal"-->
      <!--          @saveOnCloseModal="saveOnCloseModal"-->
      <!--        />-->
      <!--      </a-col>-->
    </a-row>
  </a-modal>

  <!--  <iss-form-edit-->
  <!--    v-model:visible="formVisible"-->
  <!--    :init-value="activeItem"-->
  <!--    @fnOk="handleFnOkByEdit"-->
  <!--    @update="formUpdate"-->
  <!--  />-->
</template>

<script>
// import { useStore } from 'vuex';
import http from '@/utils/http';
import { copyText } from '@/utils';
import Grid from '@/components/grid';
import promoteApi from '@/api/promote';
import MTOne from './paneOne/modalTabOne.vue';
import MTTwo from './paneOne/modalTabTwo.vue';
import MTFour from './paneOne/modalTabFour.vue';
import { reactive, ref, toRefs, watchEffect } from 'vue';
import Operation from '@/components/operation';
import SearchForm from '@/components/searchForm';
// import MTThree from './paneOne/modalTabThree.vue';
import MTFive from './paneOne/modalTabFive.vue';
import { useRouter } from 'vue-router';
// import IssFormEdit from './paneOne/updatePop.vue';
import { local } from '@/utils/storage';
// import html2canvas from 'html2canvas';
import { ExclamationOutlined } from '@ant-design/icons-vue';
import { Row, Col, message, Avatar } from 'ant-design-vue';

export default {
  components: {
    Grid,
    Operation,
    SearchForm,
    MTOne,
    MTTwo,
    ARow: Row,
    ACol: Col,
    AAvatar: Avatar,

    // ADivider: Divider,
    // ASwitch: Switch,
    ExclamationOutlined,
    // IssFormEdit,
    // MTThree,
    MTFour,
    MTFive,
  },
  setup() {
    const gridRef = ref();
    const mainRef = ref();
    const modalRef = ref();
    // const store = useStore();
    // const route = useRoute();
    const router = useRouter();
    const vueqrDownloadRef = ref(); //Ref
    const state = reactive({
      search: { campaignName: '', promoteType: '', resourceType: '' },
      visible: false, // 新增
      // formVisible: false, //目标弹窗控制
      activeKey: 1, // 默认为会邀的新增
      // activeItem: {}, // 推广目标传值
      show: 0,
      canvasMapData: [],
    });
    watchEffect(() => {
      state.search.resourceType = state.search.promoteType;
      ['article', 'file'].includes(state.search.resourceType) &&
        (state.search.promoteType = 2);
    });
    // 保存成功刷新
    const saveOnCloseModal = c => {
      gridRef.value.refreshGrid();
      state.visible = c;
    };
    // 关闭弹窗
    const closeModal = c => {
      state.visible = c;
    };
    http
      .get(promoteApi.campaignPageUrl, { params: { current: 1, size: 10 } })
      .then(res => {
        state.show = res.records.length;
      });

    // 操作按钮配置
    const options = [
      {
        type: 'config',
        label: '推广配置',
        icon: 'SettingTwoTone',
        permission: 'task:config',
        fnClick: record => {
          local.set('configData', record);
          console.log(local.get('configData'));
          // store.commit('common/setConfigData', record);
          let type = {
            1: 'meetingConfig',
            2: 'materialConfig',
            5: 'posterConfig',
          };
          router.push({
            name: type[record.promoteType],
          });
        },
      },
      {
        type: 'delete',
        permission: 'task:delete',
        fnClick: ({ id }) => {
          promoteApi.promoteDelete('', id).then(() => {
            message.success('操作成功');
            gridRef.value.refreshGrid();
          });
        },
      },
    ];
    return {
      ...toRefs(state),
      promoteType: {
        1: '会议邀请',
        // 3: '自定义',
      },
      items: [
        { key: 'promoteName', label: '任务名称' },
        {
          key: 'promoteType',
          label: '任务类型：',
          type: 'select',
          dataset: [
            { label: '所有类别', value: '' },
            { label: '会议邀请', value: '1' },
            { label: '资料推广', value: 'file' },
            { label: '图文任务', value: 'article' },
            { label: '裂变海报', value: '5' },
          ],
        },
      ],
      columns: [
        {
          dataIndex: 'promoteName',
          title: '任务名称',
          width: 220,
          ellipsis: true,
        },
        {
          dataIndex: 'promoteType',
          title: '任务类型',
          slots: { customRender: 'promoteType' },
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'createTime',
          title: '创建时间',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'createUserName',
          title: '创建者',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'promoterNumber',
          title: '领取人数',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'visitorNumber',
          title: '访问人数',
          width: 100,
          ellipsis: true,
        },
        {
          dataIndex: 'conversionNumber',
          title: '转化人数',
          slots: { customRender: 'conversionNumber' },
        },
        {
          dataIndex: 'id',
          fixed: 'right',
          title: '操作',
          width: 100,
          slots: { customRender: 'operation' },
        },
      ],
      //添加按钮
      btnOperation: [
        {
          type: 'add',
          permission: 'extendTask:add',
          fnClick: () => {
            state.activeKey = 1;
            state.visible = true;
          },
        },
      ],
      handleFnSearch: value => {
        state.search = value;
      },
      // handleFnOkByEdit: value => {
      //   promoteApi.promoteUpdate('', value).then(() => {
      //     message.success('操作成功');
      //     state.formVisible = false;
      //     gridRef.value.refreshGrid();
      //   });
      // },
      handleByEnabled: async ({ id, enabled, promoteType }) => {
        let total = 0;
        await promoteApi
          .marketingPosterPage('', { promoteId: id })
          .then(res => {
            total += res?.total;
          });
        await promoteApi.marketingLinkSelect('', id).then(res => {
          res && (total += 1);
        });
        total == 0 && promoteType !== 2
          ? message.info('请进入推广配置，配置分享设置或者配置海报，才可启用~')
          : promoteApi
              .promoteUpdateEnabled('', { id, enabled: !enabled })
              .then(() => {
                message.success('操作成功');
                gridRef.value.refreshGrid();
              });
      },
      handleClickByPreview: value => {
        //  0 是默认值
        promoteApi.promoteDataPreview('', value.resourceId || 0).then(res => {
          if (value.resourceType !== 'article') {
            value.resourceType == 'file'
              ? window.open(res.fileUrl)
              : window.open(value.promoteUrl);
          } else {
            if (!res) return message.error('无资料预览');
            if (res.fileType == 'article' || res.fileType == 'file') {
              let time = new Date().getTime();
              time = time + 3600 * 1000;
              // local.set('previewHtml', res.article);
              document.cookie = `resourceId={"id":"${
                res.id
              }","token":"${local.get('TOKEN')}","tenant":"${local.get(
                'TENANT'
              )}"}; expires=${time};path=/; domain=.issmart.com.cn`;
              window.open(`${process.env.VUE_APP_CLIENT_URL}/#/preview`);
            }
          }
        });
      },
      // 表格数据请求链接
      url: promoteApi.pageUrl,
      gridHeight: document.body.clientHeight - 359,
      gridRef,
      mainRef,
      modalRef,
      vueqrDownloadRef,
      // form,
      handleClickByCopyLink: link => {
        copyText(link);
        message.success('复制链接成功！');
      },
      // formUpdate: formVisible => {
      //   state.formVisible = formVisible;
      // },
      closeModal,
      saveOnCloseModal,
      getOptions: ({ promoteType }) =>
        [1, 3].includes(promoteType) ? options : [...options],
      // handleClickSearch,
      // changeStyle,
      // afterClose,
    };
  },
};
</script>
<style lang="less" scoped>
.iss-main {
  //max-height: calc(100vh - 185px);
  overflow-y: auto;
}
.modal {
  .ant-btn {
    width: 85%;
  }
}
.not-open {
  text-align: center;
  padding: 100px 0px 100px 0px;
}
.qrcode-ver {
  align-items: center;
  justify-content: center;
  text-align: center;
}
.qrcode-url {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: #5979f8;
  border-color: #5979f8;
}
.vue-qr {
  .qr {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.item-content_demo {
  /*height:670px;*/
  /* width:375px;*/
  height: 100%;
  width: 100%;
  position: relative;
  img {
    width: 100%;
    /*height: 100%;*/
  }
  .qrcode {
    position: absolute;
  }
}
</style>
