<template>
  <search-form
    :items="items"
    :layout="{ sm: 24, md: 12, lg: 8 }"
    @fnSearch="handleFnSearch"
  />
  <grid
    ref="gridRef"
    :columns="columns"
    :code="$route.path"
    :url="url"
    :allow-selection="{ type: 'radio' }"
    :search="search"
    :scroll="{ x: 900, y: gridHeight }"
  >
    <template #operation="{ record }">
      <operation :options="options" :record="record" />
    </template>
  </grid>
  <br />
  <a-form
    ref="formRef"
    :model="form"
    :label-col="{ span: 3 }"
    :wrapper-col="{ span: 20 }"
  >
    <!--    <a-form-item-->
    <!--      label="选择banner图："-->
    <!--      extra="建议上传大小不超过10M，格式为JPG、JPEG或PNG的图片"-->
    <!--    >-->
    <!--      <iss-image-upload-->
    <!--        v-model:value="form.banner"-->
    <!--        list-type="picture-card"-->
    <!--        accept=".jpe,.jpeg,.jpg,.png"-->
    <!--      />-->
    <!--    </a-form-item>-->
    <a-form-item label="说明">
      <a-textarea v-model:value="form.remark" placeholder="请输入说明" />
    </a-form-item>
  </a-form>
  <br />
  <a-space>
    <a-button @click="onSubmit" type="primary">保存</a-button>
    <a-button @click="closeModal">取消</a-button>
  </a-space>
</template>

<script>
import { reactive, ref, toRaw, toRefs } from 'vue';
import Grid from '@/components/grid';
import promoteApi from '@/api/promote';
import SearchForm from '@/components/searchForm';
import { Form, message, Space } from 'ant-design-vue';

export default {
  components: {
    Grid,
    SearchForm,
    AForm: Form,
    AFormItem: Form.Item,
    ASpace: Space,
  },
  setup(props, context) {
    // const route = useRoute();
    let isVisible = ref(true);
    const formRef = ref();
    const form = reactive({
      // banner: '',
      remark: '',
    });
    const gridRef = ref();
    const formState = reactive({
      messageName: '',
      name2: 'messageCom',
      name3: '',
      name4: '',
      isShortLink: true,
    });
    const state = reactive({
      search: {},
    });
    const onSubmit = () => {
      formRef.value.validate().then(() => {
        const rowData = gridRef.value.selectedRows;
        if (rowData.length > 0) {
          rowData.map(i => {
            let da = {
              campaignId: i.campaignId,
              formId: i.formId,
              promoteName: `${i.campaignName}_${i.formName}`,
              promoteType: 1,
              enabled: true,
              ...toRaw(form),
            };
            promoteApi.promoteAdd('', da).then(() => {
              message.success('操作成功');
              context.emit('saveOnCloseModal', false);
            });
          });
        } else {
          message.error('请选择推广任务');
        }
      });
    };
    const closeModal = () => {
      context.emit('closeModal', false);
    };

    return {
      gridRef,
      url: promoteApi.campaignPageUrl,
      items: [
        { key: 'campaignName', label: '会议名称' },
        { key: 'formName', label: '表单名称' },
      ],
      columns: [
        { dataIndex: 'formName', title: '表单名称', key: 'formName' },
        {
          dataIndex: 'formStartTime',
          title: '表单开启时间',
          key: 'formStartTime',
        },
        { dataIndex: 'campaignName', title: '会议名称', key: 'campaignName' },
        {
          dataIndex: 'campaignStartTime',
          title: '会议时间',
          key: 'campaignStartTime',
        },
      ],
      isVisible,
      formState,
      formRef,
      form,
      ...toRefs(state),
      onSubmit,
      closeModal,
      gridHeight: document.body.clientHeight - 472,
      handleFnSearch: value => {
        state.search = value;
      },
    };
  },
};
</script>

<style scoped></style>
